import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {

//This is used to dynamically remove array item
       $('form').on('click', '.remove-array-item', function(event) {                     
         $(this).closest('form').setChanged();
         $(this).attr('disabled', true);
         $(this).prev('.array-item').val("");
         $(this).closest('div').tooltip('hide');
         var fieldset = $(this).closest('div');
         fieldset.removeClass( "d-flex" ).addClass( "d-none" );
         $(this).removeAttr('disabled');
         return event.preventDefault();
       });

       var previous;
//This is used to dynamically add array items
       return $('form').on('focus', '.array-item', function () {
        // Store the current value on focus and on change
        previous = $(this).val();
        }).on('change', '.array-item', function(event) {

          console.log("GOT HERE");

        //get the parent div to check if it's the last in the list
        var itemContainer = $(this).closest('div');
        var list = $(this).closest('.row');
        var currentItem = $(this);

        list.find('.array-item').not(this).each(function(){
          //if someone reselects a value that has been used already then we will display a popup message and set the value to null
          if (currentItem.val() == $(this).val()) {
            //TODO: Make this translatable
            window.alert("The value has already been selected. Please select a different value.");       
            currentItem.val(previous);                     
          }            
        });        

        //Only add a new item if there is a value in this item
        if( $(this).val() ) {
          
          previous = $(this).val();

          if ( itemContainer.is(':last-child') ) {            
            var regexp, time;
            time = new Date().getTime();
            regexp = new RegExp($(this).data('id'), 'g');        
            itemContainer.clone().appendTo(list).find("input[type='text']").val("");    //first we clone the div with the delete button still hidden. We also clear out the current value
            $(this).next('.remove-array-item').removeClass( "d-none" );    //then we unhide the delete button          
            $(this).closest('form').setChanged();
            $(this).closest('form').trackChanges();            
          }          

          return event.preventDefault();
        }

        
       });

 }

}
