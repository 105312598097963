import { Controller } from 'stimulus';
export default class extends Controller {

  initialize() {
    
  }

 connect() {

  $(function() {


     $('.is-invalid').on('change', function(e){
       $(this).removeClass('is-invalid');
       $('.dma-validate-button').prop('disabled', false);
       $('.dma-validate-button').show();
       var feedback = $(this).next('.invalid-feedback');
       feedback.hide();

       if ($(this).closest('table').find('.is-invalid').length == 0) {

         var current_pane = $(this).closest('.tab-pane');
         var current_tab_id = "#";
         current_tab_id = current_tab_id.concat(current_pane.attr('id'),"Tab");

         $(current_tab_id).find('.dma-tab-icon').hide();
       }
      });

    $('.is-valid').on('change', function(e){
      $(this).removeClass('is-valid');
      $('.dma-validate-button').prop('disabled', false);      
      $('.dma-validate-button').show();
      $('.dma-submit-button').hide();


      if ($(this).closest('table').find('.is-invalid').length == 0) {

        var current_pane = $(this).closest('.tab-pane');
        var current_tab_id = "#";
        current_tab_id = current_tab_id.concat(current_pane.attr('id'),"Tab");

        $(current_tab_id).find('.dma-tab-icon').hide();
      }
     });         

     var dma_job_id = $("#dma_job_id").val();

     if (dma_job_id != null) {
      get_status(dma_job_id).then( response => 
      {        

        var jobStatus = "";
        var resultArray = JSON.parse(response);

        $.each(resultArray, function(k, v) {
          
          if (k == "status") {
            jobStatus = v;
          }
        });

        if (jobStatus == "working" || jobStatus == "queued") {
          $('#dmaProcessingModal').modal('show');
          $('.migration-assisstant-area').hide();
          $('.step-footer').hide();

          var intervalId = window.setInterval(function()
          {
            get_status(dma_job_id).then( response => 
            {
              var resultArray = JSON.parse(response);
              
              var resultArray = JSON.parse(response);

              var progressTotal = 0;
              var progressAt = 0;

              $.each(resultArray, function(k, v) {
                
                if (k == "status") {
                  jobStatus = v;
                  if (jobStatus == "" || jobStatus == "complete") {
                    clearInterval(intervalId);
                    window.location.reload(true);
                  }
                } else if (k == "total") {

                  progressTotal = v;

                } else if (k == "at") {
                  progressAt = v;
                }

              });

              var progressWidth = Math.round(progressAt/progressTotal*100);

              $('#dmaProgressBar').width(progressWidth + "%");
              $('#dmaProgressBar').text(progressWidth + "%");
            });                       
          }, 3500);
  
        }
      });
     }     

    });
 }

 submit_data() {
  if (window.confirm("Are you sure you want to submit the data and finalise the activation?")) {
    var disableText = $('.dma-submit-button').data( "disable-with" );
    $('.dma-submit-button').prop('disabled', true);
    $('.dma-submit-button').html(disableText);
//NB: This JS controller should always be called from DMA Index
    var submit_url = window.location.href + '/submit_data'

   $.ajax({
         type: "POST",
         url: submit_url,
         data: new FormData($('.data-migration-assistant-form')[0]),
         processData: false,
         contentType: false,
         dataType: "script",
         success: function(result) {

           $('.activation-form').resetChanges();

         },
         error: function(result) {

         }
     });
  }
   
 }

 validate_data() {
  var disableText = $('.dma-validate-button').data( "disable-with" );
  $('.dma-validate-button').prop('disabled', true);
  $('.dma-validate-button').html(disableText);
//NB: This JS controller should always be called from DMA Index
   var validate_url = window.location.href + '/validate_data'

   $.ajax({
         type: "POST",
         url: validate_url,
         data: new FormData($('.data-migration-assistant-form')[0]),
         processData: false,
         contentType: false,
         dataType: "script",
         success: function(result) {

         },
         error: function(result) {

         }
     });

 } 

}

function get_status(job_id) {
  //NB: This JS controller should always be called from DMA Index
  var worker_status_url = window.location.href + '/worker_status'  

   return $.ajax({
         type: "GET",
         url: worker_status_url,
         data: "dma_job_id=" + job_id,
         processData: false,
         contentType: false,
         dataType: "text",
         success: function(result) {

          

         },
         error: function(result) {
          returnValue = "error";
         }
     });     

 }