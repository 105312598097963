import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {

//This is used to dynamically remove child rows
       $('form').on('click', '.remove_rows', function(event) {
         $(this).closest('form').setChanged();
         $(this).attr('disabled', true);
         $(this).prev('input[type=hidden]').val('1');
         $(this).closest('div').tooltip('hide');
         var fieldset = $(this).closest('tr');
         fieldset.hide();
         $(this).removeAttr('disabled');
         return event.preventDefault();
       });
//This is used to dynamically remove multiple child rows at once
        $('.remove_selected_rows').on('click', function(event) {
          $(this).attr('disabled', true);
          $(this).closest('table').find('.delete-flag').each(function () {

            if (this.checked) {

              $(this).closest('form').setChanged();
              $(this).next("input[type=hidden]").val('1');
              var fieldset = $(this).closest('tr');
              fieldset.removeClass( "d-flex" );
              fieldset.hide();
            }
          });
          $(this).removeAttr('disabled');
          return event.preventDefault();
        });
//This is used to dynamically add child rows
       return $('form').on('click', '.add_rows', function(event) {
         $(this).attr('disabled', true);
         var regexp, time;
         time = new Date().getTime();
         regexp = new RegExp($(this).data('id'), 'g');

         var table = $(this).closest('table').find('tbody').append($(this).data('fields').replace(regexp, time));
         $(this).closest('form').setChanged();
         $(this).closest('form').trackChanges();
         $(this).removeAttr('disabled');
         return event.preventDefault();
       });

 }

}
