import { Controller } from 'stimulus';
export default class extends Controller {
 connect() {

   $(function () {
   //enable track changes if there isn't an override
    if ($('.activation-form[data-do-not-track]').length == 0 ) {
      $('.activation-form').trackChanges();
    };
     

       // Another way to bind the event
      $(window).on('beforeunload', function() {
          if($('.activation-form').isChanged()){
              return "You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?";
          }
      });

    });
 }

  submit_form() {

    if ($('.activation-form').isChanged() ) {
      $.ajax({
            type: "PUT",
            url: $('.activation-form').attr('action'),
            data: new FormData($('.activation-form')[0]),
            processData: false,
            contentType: false,
            dataType: "script",
            success: function(result) {                            
              $('.activation-form').resetChanges();

              console.log("Is changed? " + $('.activation-form').isChanged());
            },
            error: function(result) {

            }
        });
      } else {
        if ($('.activation-form[data-do-not-track]').length == 0 ) {
          $('.status-panel').slideDown("slow");
          $("#success_status_icon").hide();
          $("#error_status_icon").hide();
          $("#info_status_icon").show();
          $("#status_text_field").text("No changes were found");
          setTimeout(hideStatus, 7500);
        } else { //always submit the form if we do not track changes
          $.ajax({
            type: "PUT",
            url: $('.activation-form').attr('action'),
            data: new FormData($('.activation-form')[0]),
            processData: false,
            contentType: false,
            dataType: "script",
            success: function(result) {                            

            },
            error: function(result) {

            }
          });
        }
      }

  }

  submit_and_continue() {
    var submit_and_continue_url = $('.activation-form').attr('action') + '/submit_and_continue'

    $.ajax({
          type: "PUT",
          url: submit_and_continue_url,
          data: new FormData($('.activation-form')[0]),
          processData: false,
          contentType: false,
          dataType: "script",
          success: function(result) {
            $('.activation-form').resetChanges();
          },
          error: function(result) {

          }
      });

  }
}

function clearPage() {
  location.reload;
}

function hideStatus() {
  $('.status-panel').slideUp("slow");
  clearTimeout(hideStatus);
}

$.fn.extend({
trackChanges: function() {
  $(":input",this).on("change",function() {
     $(this.form).setChanged();     
  });
}
,
isChanged: function() {
  return $(this).data("changed");
}
,
setChanged: function() {
  
  if ( !$(this).data("demo")){
    $(this).data("changed", true);

  }
}
,
resetChanges: function() {

  $(this).data("changed", false);
  // Enable navigation prompt
  window.onbeforeunload = null;
}
});
