import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {

  }


 show_org_structure(){

  var orgViewerChart = $('#OrganiseChart1');
  //initialise the HTML
  orgViewerChart.empty();

  var orgViewerModal = $('#orgViewerModal');

  var chart_config  = get_org_entries();

  orgViewerModal.on('shown.bs.modal', function(){
    var tree = new Treant(chart_config);
  });

  orgViewerModal.modal('show');

  return event.preventDefault();

 }

}

function get_org_entries() {
  //Toggle tree view visibility
   var org_entries = [];

   var config = {
     container: "#OrganiseChart1",

     rootOrientation: 'NORTH', // NORTH || EAST || WEST || SOUTH
     hideRootNode: true,
     // levelSeparation: 30,
     siblingSeparation: 40,
     subTeeSeparation: 30,

     connectors: {
       type: 'curve'
     },
     node: {
        collapsable: true
     },
     animation: {
         nodeAnimation: "easeOutBounce",
         nodeSpeed: 700,
         connectorsAnimation: "bounce",
         connectorsSpeed: 700
     }
   }

   org_entries.push(config);

   var root_item = {};

   org_entries.push(root_item);

   var counter = 1;
   var collapsed = false;   

    var level_1_structures = $(".form-body").children().filter(function() {
      return $(this).css("display") != "none"
    });

    level_1_structures.each(function () {
      var l1_item = {};
      var l1_id = counter;
      counter++;      

       l1_item = {
        "parent": root_item,
        "text": { name: $(this).find(".structure-name").first().val() },
        "HTMLclass": "nodeType1",
        "collapsed": collapsed
       };

     org_entries.push(l1_item);

      var level_2_structures = $("#"+$(this).attr('id')+"-children").children().filter(function() {
        return $(this).css("display") != "none"
      });

      level_2_structures.each(function () {
        var l2_item = {};
        var l2_id = counter;
        counter++;

        l2_item = {
         "parent": l1_item,
         "text": { name: $(this).find(".structure-name").first().val() },
         "HTMLclass": "nodeType2",
         "collapsed": collapsed
        };

        org_entries.push(l2_item);

        //Collapse all subsequent branches - i.e. only show first l2 branch
        collapsed = true;

         var level_3_structures = $("#"+$(this).attr('id')+"-children").children().filter(function() {
           return $(this).css("display") != "none"
         });         

          level_3_structures.each(function () {
            var l3_item = {};
            var l3_id = counter;
            counter++;

            l3_item = {
              "parent": l2_item,
              "text": { name: $(this).find(".structure-name").first().val() },
              "HTMLclass": "nodeType3"              
             };
            
             org_entries.push(l3_item);             

          });
      });

    });

    return org_entries;
}
